<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- 增加门店和主机的绑定关系，查看门店绑定的主机信息-->
<template>

  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="showModal.addOrEdit"
           title="设备信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 13
   }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex">
                <a-cascader :disabled="editFlag"
                            v-if="['storeId'].includes(item.dataIndex)"
                            v-model:value="baseData[item.dataIndex]"
                            :options="options.storeList"
                            :show-search="{ filter }"
                            placeholder="请选择门店"
                            @change="storeChange" />
                
                <a-input-number v-else-if="['gateway_port'].includes(item.dataIndex)"
                                v-model:value="baseData['gateway_port']"
                                style='width:200px'
                                :placeholder="`请输入${item.title}`" />
                <a-input 
                         v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <a-modal width="400px"
           v-model:open="showModal.bindGateway"
           title="关联网关"
           ok-text="确认"
           cancel-text="取消"
           @ok="gatewayOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="gateway"
              ref="formRef"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 13
   }"
              :rules="rules">
        <a-row :gutter="24">
          <a-col :span="12">
            <a-form-item label="网关"
                         :name='gatewayId'>
              <a-cascader style='width:200px'
                          v-model:value="gateway.gatewayId"
                          :options="options.gateway"
                          placeholder="请选择网关" />
            </a-form-item>

          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='container'>
    <div class='sensor-detail-modal'
         v-if="showModal.viewInterfacesFlow">
      <div class='message-box'>
        <img @click='closeModal'
             class='close-image'
             src='@/static/common/关闭.png' />
 
      </div>
    </div>
    <TableComponent :columns="fieldAll.columns"
                    :data-source="dataSource.data"
                    :row-selection="rowSelection"
                    :pagination="pagination"
                    @change="handleTableChange"
                    bordered>
      <template #bodyCell="{ column, record }">

        <template v-if="['host'].includes(column.dataIndex)">
          <a class='editable-row-a'
             @click="toWeb(record.web)">{{record.host}}</a>
        </template>
        <template v-if="['names'].includes(column.dataIndex)">
          <div>
            <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
          </div>
        </template>

        <template v-if="['operationalStatus'].includes(column.dataIndex)">
          <div class="status-light">
            <a @click="operationalStatusClick(record.id)"
               v-if='record.operationalStatus'
               class="green-light"></a>
            <a @click="operationalStatusClick(record.id)"
               v-if='!record.operationalStatus'
               class="red-light"></a>

          </div>
        </template>
        <template v-else-if="column.dataIndex === 'operation'">
          <div class="editable-row-operations">
            <a 
               class='editable-row-a'
               @click="editData(record)">编辑</a>
            <a class='editable-row-a' @click="deleteData(record.key)">删除</a>
    
           
            <!-- <a class='editable-row-a' @click="bindTrigger(record.key,record.zabbix_host_id)">触发器</a> -->
          </div>
        </template>

      </template>
      <template #title>
        <div class='searchDivContainer'>

          <template v-for="item in searchFields"
                    :key='item.key'>

            <label style='margin-right:5px'>{{item.title}}</label>
            <a-tree-select class='searchItem'
                           v-if='item.title=="区域"'
                           v-model:value="searchData[item.key]"
                           show-search
                           :placeholder="`请选择${item.title}`"
                           allow-clear
                           :tree-data="treeData"
                           tree-node-filter-prop="label"></a-tree-select>
            <a-select class='searchItem'
                      v-else-if='item.title=="关联状态"'
                      :options="options.freeStore"
                      v-model:value="searchData[item.key]"
                      allow-clear
                      :placeholder="`请选择${item.title}`"></a-select>
            <a-input class='searchItem'
                     v-else
                     v-model:value='searchData[item.key]'
                     :placeholder="`请输入${item.title}`"></a-input>

            <!-- </a-form-item> -->

          </template>

          <!-- <a-select style='width:100px' v-model:value="searchData.field" :options="options.search" defaultValue=''
          placeholder="搜索字段" change-on-select />

        <a-input v-model:value="searchData.input" style="width:150px"></a-input> -->
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
          <div class='right-buttons'>
            <a-button class="addDataButton"
                      @click="refresh">刷新</a-button>
            <a-button class="addDataButton"
                      type="primary"
                      @click="addData">添加</a-button>

            <a-button class="addDataButton"
                      @click="deleteData">删除数据</a-button>

                <a-button class="addDataButton"
                      @click="defaultImport">导入默认配置</a-button>
          </div>
        </div>
      </template>
      <!-- <template #footer>Footer</template> -->
    </TableComponent>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import * as echarts from "echarts";
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import { commonFun } from '@/utils/commonFun'
import { storeMessage } from '@/utils/commonData'
import dayjs from "dayjs"
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: '/v1/asset/asset_type/add',
  delete: '/v1/asset/asset_type/delete',
  edit: '/v1/asset/asset_type/edit',
  page: '/v1/asset/asset_type/page',
  refresh: '/v1/asset/asset_type/page',
}
const router = useRouter()

//获取门店信息
const getStoreMessage = (typeId) => {
  storeMessage(typeId).then(result => {
    console.log(result)
    options.storeList = result
  }).catch(error => {
    console.log(error)
  })

}
//确实加载指示符
const spinning = ref(false);


//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


const searchData = reactive({
  keyword: null,
  regionId: null,
  freeStore: null
})

const searchFields = [{
  title: "关键字",
  key: 'keyword'
},

]
const getButton = () => {
  let route = useRoute()
  buttonShow = commonFun.getButtonRules(route.fullPath, url)
}
getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [
    // {
    //   title: "ID",
    //   dataIndex: "id",
    //   key: "id"
    // },
    {
      title: "名称",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
      ellipsis: true,
    },
 
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 300
    }
  ],

  //弹框字段数据
  modalData: [  {
      title: "名称",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
      ellipsis: true,
    },
 



  ]

})

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],


})

//增加或者编辑的模态框规则
const rules = {
  name: [
    {
      required: true,
      message: "请输入名称",
    },
  ],

}

//选择框数据
const options = reactive({
  freeStore: [{ label: "未关联", value: 1 }, { label: "已关联", value: 0 }],
  interfaceNumber: [{
    label: '4',
    value: 4
  },
  {
    label: '8',
    value: 8
  }],
  brand: [],

  storeList: [],
  trigger: [],
  gateway: [],
});

// 数据模型
const baseData = reactive({
  id: "",
  name: "",
  desc: "",
})
const gateway = reactive({
  storeId: "",
  gatewayId: ""
}) // 关联网关绑定的变量
let physicalInterfaceList = ref([
  // {
  //   id: null,
  //   eth_number: "",
  //   name: "",
  //   upload_trigger_id: "",
  //   download_trigger_id: "",
  //   status_trigger_id: "",
  //   signal_trigger_id: "",
  //   desc: ""
  // }
])
//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false,
  bindGateway: false,
  showBaseStoreMessage: false,
  viewInterfacesFlow: false
})

//触发器模型数据
const triggerData = reactive({
  interfaces: [{
    id: 0,
    eth_number: 0,
    name: "string",
    upload_trigger_id: null,
    download_trigger_id: null,
    status_trigger_id: null,
    signal_trigger_id: null,
    desc: "string"

  }],//接口信息
  gatewayId: '',//触发器ID
  runtime_trigger_id: null,
  ping_trigger_id: null,
  mem_trigger_id: null,
  physical_interfaces_count: "",
})


let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = async () => {
  const res = await serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  })
    pagination.total = res.data.data.total;

    // 更新表格数据

    dataSource.data = res.data.data.list.map(item=>({
      ...item,
      key:item.id
    }))




}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData['interfacePort'] = 161
};

const formRef = ref()

const editFlag = ref(false)
//编辑数据
const editData = (record) => {
  editFlag.value = true
  getStoreMessage(null)
  console.log("record", record);
  urlFlag = url.edit
  showModal.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }

};


//增加数据
const addData = () => {
  editFlag.value = false

  resetForm();
  urlFlag = url.add
  getStoreMessage(0)
  console.log('rowData', rowData)

  //勾选自动填充门店名称 门店ID 品牌等信息
  const selectedFilter = rowData.filter(item => !item.gateway)
  if (selectedFilter.length != 0) {
    const record = selectedFilter[0]
    for (let key in baseData) {
      baseData[key] = record[key]
    }
    baseData['interfacePort'] = 161
    baseData.storeId = [record.item.customer.name, record.item.id]

  }


  showModal.addOrEdit = true;

};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};





//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      name: baseData.name,
      desc: baseData.desc
    }
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {

      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
        //重置数据选择
        rowData = [];
        selectedRowKey.value = []; // 选中的行的key
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false


    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch(error) {
    console.log(error)
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  console.log('searchData.freeStore', searchData)
  queryField['keyword'] = searchData.keyword ? searchData.keyword : null;
  queryField['region_id'] = searchData.regionId ? searchData.regionId : null;
  queryField['free_store'] = searchData.freeStore;
  console.log("queryField", queryField)
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  for (let key in searchData) {
    searchData[key] = null
  }
  queryPage()
}

const storeChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  baseData['brand'] = selectedOptions[0].label
  baseData['storeName'] = selectedOptions[1].label
  // baseData.storeId = selectedOptions[1].value
}


const refresh = () => {
  serviceAxios({
    url: url.refresh,
    method: 'get'

  }).then(
    result => {
      if (result.data.message == 'success') {
        message.success('刷新成功')
        queryPage()
      }
      else {
        message.error(result.data.message)
      }
    }
  )
}




// 选择框搜索
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
// 级联选择框 搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};



const defaultImport = async()=>{
  const res = await serviceAxios({
    url:'/v1/asset/asset_type/import_default',
    method:'post'
  })

  if (res.data.message=='success'){
    message.success('导入默认配置成功')
    queryPage()
  }
  else {

    message.err(res.data.message)
  }
}

</script>
<style lang="less" scoped>
@import "@/assets/css/modalTable.css";
@import "@/assets/css/modal.css";
.container {
  width: 100%;
  height: 100%;
}
.searchDivContainer {
  padding-top: 0px;
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
  margin-bottom: 2px;
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-a {
  margin: 2px;
  margin-left: 5px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
.searchItem {
  width: 150px;
  margin-right: 10px;
}

.interfaces-box {
  display: flex;
  .interfaces-item {
    padding: 18px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
  }
  .interfaces-item-no-selected {
    padding: 18px;
    cursor: pointer;
    font-size: 20px;
  }
}
</style>