<!-- eslint-disable vue/valid-v-else-if -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- 增加门店和主机的绑定关系，查看门店绑定的主机信息-->
<template>

  <div class='container'>
    <div class='sensor-detail-modal'
         v-if="showModal.viewInterfacesFlow">
      <div class='message-box'>
        <img @click='closeModal'
             class='close-image'
             src='@/static/common/关闭.png' />
 
      </div>
    </div>
    <TableComponent :columns="fieldAll.columns"
                    :data-source="dataSource.data"
                    :row-selection="rowSelection"
                    :pagination="pagination"
                    @change="handleTableChange"
                    bordered>
      <template #bodyCell="{ column, record }">

        <template v-if="['host'].includes(column.dataIndex)">
          <a class='editable-row-a'
             @click="toWeb(record.web)">{{record.host}}</a>
        </template>
        <template v-if="['names'].includes(column.dataIndex)">
          <div>
            <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
          </div>
        </template>

        <template v-if="['operationalStatus'].includes(column.dataIndex)">
          <div class="status-light">
            <a @click="operationalStatusClick(record.id)"
               v-if='record.operationalStatus'
               class="green-light"></a>
            <a @click="operationalStatusClick(record.id)"
               v-if='!record.operationalStatus'
               class="red-light"></a>

          </div>
        </template>
        <template v-else-if="column.dataIndex === 'operation'">
          <div class="editable-row-operations">
             <a 
               class='editable-row-a'
               v-if="record.status=='未使用'"
               @click="assetIssue(record)">资产发放</a>
                <a 
               class='editable-row-a'
               v-if="record.status=='使用中'"
               @click="assetRecycle(record)">资产回收</a>
            <a 
               class='editable-row-a'
               @click="editData(record)">编辑</a>
            <a class='editable-row-a' @click="deleteData(record.key)">删除</a>
    
           
          </div>
        </template>

      </template>
      <template #title>
        <div class='searchDivContainer'>
          <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 13
   }">
          <a-row :gutter="24">
          <template   v-for="item in searchFields"
                    :key='item.key'>
            <a-col :span="4">

            <a-form-item :label="item.title"
                           :name="item.dataIndex">
            <a-tree-select class='searchItem'
                           v-if='["dept_id"].includes(item.key)'
                           v-model:value="searchData[item.key]"
                           show-search
                           :placeholder="`请选择${item.title}`"
                           allow-clear
                           :tree-data="options[item.key]"
                           tree-node-filter-prop="label"></a-tree-select>
            <a-select class='searchItem'
                      v-else-if='["user_id","issuance_operator_id","withdraw_operator_id"].includes(item.key)'
                      :options="options['user_id']"
                      v-model:value="searchData[item.key]"
                      allow-clear
                      show-search
                       :filter-option="filterOption"
                      :placeholder="`请选择${item.title}`"></a-select>
            <a-config-provider :locale="locale" v-else-if="['start_time','end_time'].includes(item.key)">
                  <a-date-picker show-time placeholder="请选择开始时间"  v-model:value="searchData[item.key]"  @change="onChange" @ok="onOk"  format="YYYY-MM-DD HH:mm:ss"  valueFormat='YYYY-MM-DD HH:mm:ss'/>
            </a-config-provider>
             <a-cascader 
                            v-else-if="['store_id','model_id'].includes(item.key)"
                            v-model:value="searchData[item.key]"
                            :options="options[item.key]"
                            :show-search="{ filter }"
                            :placeholder="`请选择${item.title}`"
                             />
              
            <a-input class='searchItem'
                     v-else
                     v-model:value='searchData[item.key]'
                     :placeholder="`请输入${item.title}`"></a-input>
              </a-form-item>
          </a-col>

            <!-- </a-form-item> -->
          </template>
          <a-col :span="4">
            <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
            <a-button class="addDataButton"
                     style="margin:2px"
                      @click="refresh">刷新</a-button>
          </a-col>
          </a-row>
          </a-form>
       
          
        </div>
      </template>
      <!-- <template #footer>Footer</template> -->
    </TableComponent>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import * as echarts from "echarts";
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import { commonFun } from '@/utils/commonFun'
import { storeMessage } from '@/utils/commonData'
import { selector } from '@/utils/selector'
import dayjs from "dayjs"
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: '/v1/asset/add',
  delete: '/v1/asset/delete',
  edit: '/v1/asset/edit',
  page: '/v1/asset/usage_record/page',
  refresh: '/v1/asset/usage_record/page',
}
const router = useRouter()


//确实加载指示符
const spinning = ref(false);
const assetIssueSpinning = ref(false);

//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


//搜索数据模型
const searchData = reactive({
  keyword: null,

})

const searchFields = [{
  title: "关键字",
  key: 'keyword'
},
{
  title: "使用记录ID",
  key: 'id'
},
{
  title: "资产ID",
  key: 'asset_id'
},
{
  title: "资产编号",
  key: 'asset_sn'
},

{
  title: "使用人",
  key: 'user_id'
},
{
  title: "发放人",
  key: 'issuance_operator_id'
},
{
  title: "回收人",
  key: 'withdraw_operator_id'
},
{
  title: "部门",
  key: 'dept_id'
},
{
  title: "门店",
  key: 'store_id'
},
{
  title: "开始时间",
  key: 'start_time'
},
{
  title: "结束时间",
  key: 'end_time'
},




]

const getButton = () => {
  let route = useRoute()
  buttonShow = commonFun.getButtonRules(route.fullPath, url)
}
getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [

  
        {
      title: "别名",
      dataIndex: "alias",
      key: "alias",
      ellipsis: true,
    },
        {
      title: "SN",
      dataIndex: "asset_sn",
      key: "asset_sn"
    },

        {
      title: "型号",
      dataIndex: "model_name",
      key: "model_name",
      ellipsis: true,
    },
          {
      title: "类型",
      dataIndex: "type_name",
      key: "type_name",
      ellipsis: true,
    },
              {
      title: "部门",
      dataIndex: "dept_name",
      key: "dept_name",
      ellipsis: true,
    },
                  {
      title: "当前使用者",
      dataIndex: "current_user_name",
      key: "current_user_name",
      ellipsis: true,
    },
                  {
      title: "发放人",
      dataIndex: "issuance_operator_name",
      key: "issuance_operator_name",
      ellipsis: true,
    },
                  {
      title: "回收人",
      dataIndex: "withdraw_operator_name",
      key: "withdraw_operator_name",
      ellipsis: true,
    },
      
    {
      title: "开始时间",
      dataIndex: "start_time",
      key: "start_time",
      ellipsis: true,
    },
        {
      title: "结束时间",
      dataIndex: "end_time",
      key: "end_time",
      ellipsis: true,
    },
   
 
    // {
    //   title: "操作",
    //   dataIndex: "operation",
    //   key: "operation",
    //   width: 300
    // }
  ],

  //弹框字段数据
  modalData: [  {
      title: "sn",
      dataIndex: "sn",
      key: "sn",

    },
    {
      title: "别名",
      dataIndex: "alias",
      key: "alias",
    },
    {
      title: "子系统",
      dataIndex: "sub_system_id",
      key: "sub_system_id",
    },
    {
      title: "采购方式",
      dataIndex: "procurement_method",
      key: "procurement_method",
    },
        {
      title: "资产型号",
      dataIndex: "asset_model_id",
      key: "asset_model_id",
    },
    // {
    //   title: "关联门店",
    //   dataIndex: "store_id",
    //   key: "store_id",
    // },
    {
      title: "部门",
      dataIndex: "dept_id",
      key: "dept_id",
    },
    {
      title: "描述",
      dataIndex: "desc",
      key: "desc",
    },
 



  ],


  assetIssue: [
    {
      title: "用户",
      dataIndex: "user_id",
      key: "user_id",
    },
    {
      title: "开始时间",
      dataIndex: "start_time",
      key: "start_time",
    },    {
      title: "是否强制回收",
      dataIndex: "force_withdraw",
      key: "force_withdraw",
    },
  ],

})

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],


})

//增加或者编辑的模态框规则
const rules = {
  sn: [
    {
      required: true,
      message: "请输入资产编码",
    },
  ],
  sub_system_id: [
    {
      required: true,
      message: "请选择子系统",
    },
  ],
  asset_model_id: [
    {
      required: true,
      message: "请选择资产型号",
    },
  ]

}

//选择框数据
const options = reactive({
  
  brand: [],
  type_id: [],
  force_withdraw:[{label:'否',value:0},{label:'是',value:1}]
});

// 数据模型
const baseData = reactive({
  id: "",
  sn: "",
  alias: "",
  sub_system_id: 0,
  asset_model_id: 0,
  procurement_method: "",
  store_id: 0,
  dept_id: 0,
  // network_node_id: 0

})


//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false,
  bindGateway: false,
  showBaseStoreMessage: false,
  viewInterfacesFlow: false,
  assetIssue: false,
})




let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = async () => {
  const res = await serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  })
    pagination.total = res.data.data.total;

    // 更新表格数据

    dataSource.data = res.data.data.list.map(item=>({
      ...item,
      key:item.id
    }))




}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData['interfacePort'] = 161
};

const formRef = ref()
const assetIssueFormRef = ref()
const editFlag = ref(false)
//编辑数据
const editData = async(record) => {
  editFlag.value = true;
  console.log("record", record);
  urlFlag = url.edit;
  showModal.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }
  const brand = await getAssetModelBrand(record.asset_model_id)

  // baseData.store_id = record.store_id
  baseData.asset_model_id =[brand,record.asset_model_id] 

};

//获取资产型号品牌信息
const getAssetModelBrand = async (assetModelId)=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_model/page',
    method: 'get',
    params: {
      id: assetModelId
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list[0].brand
  }
  else {
    message.error(res.data.message)
  }
}

//增加数据
const addData = () => {
  editFlag.value = false

  resetForm();
  urlFlag = url.add
  console.log('rowData', rowData)

  //勾选自动填充门店名称 门店ID 品牌等信息
  const selectedFilter = rowData.filter(item => !item.gateway)
  if (selectedFilter.length != 0) {
    const record = selectedFilter[0]
    for (let key in baseData) {
      baseData[key] = record[key]
    }
    baseData['interfacePort'] = 161
    baseData.storeId = [record.item.customer.name, record.item.id]

  }


  showModal.addOrEdit = true;

};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};





//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      sn: baseData.sn,
      alias: baseData.alias,
      sub_system_id: baseData.sub_system_id,
      asset_model_id: baseData.asset_model_id[1],
      procurement_method: baseData.procurement_method,
      // store_id: baseData.store_id[1],
      dept_id: baseData.dept_id,
    }
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {

      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
        //重置数据选择
        rowData = [];
        selectedRowKey.value = []; // 选中的行的key
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false


    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch(error) {
    console.log(error)
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  console.log('searchData.freeStore', searchData)
   for (let key in searchData) {
    queryField[key] = searchData[key]?searchData[key]:null
  }
  queryField['store_id'] = queryField['store_id']?queryField['store_id'][1]:null
  console.log("queryField", queryField)
  queryPage()
  message.info("搜索完成")
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  for (let key in searchData) {
    searchData[key] = null
  }
  queryPage()
}









const storeChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  baseData['brand'] = selectedOptions[0].label
  baseData['storeName'] = selectedOptions[1].label
  // baseData.storeId = selectedOptions[1].value
}


const refresh = () => {
  serviceAxios({
    url: url.refresh,
    method: 'get'

  }).then(
    result => {
      if (result.data.message == 'success') {
        message.success('刷新成功')
        queryPage()
      }
      else {
        message.error(result.data.message)
      }
    }
  )
}




const assetsTypeSelect = async  ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_brand/selector',
    method: 'get'
  })
  try{
     if (res.data.message == 'success') {
      baseData.brand = res.data.data
      let result = res.data.data.map(item=>({
        label:item.name,
        value:item.value
      }))
      return result
  }
  else {
    message.error(res.data.message)

  }
  }
  catch(err){
    console.log(err)
    message.error("请求异常，请联系管理员")
  }
 
}

const typeSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_type/page',
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.name,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}




// 选择框搜索
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
// 级联选择框 搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};



// 资产状态选择器
const assetsStatusSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_status/selector',
    method: 'get'
  })

  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.value
    }))
  }
  else {
    message.error(res.data.message)
  } 
}

// 采购方式选择器
const procurementMethodSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/procurement_method/selector',
    method: 'get'
  })

  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.value
    }))
  }
  else {
    message.error(res.data.message)
  }
}


//资产型号选择器
const assetModelSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_model/page',
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99
    }
  })

  if (res.data.message == 'success') {
    // 按品牌名称分组
       const uniqueBrands = [...new Set(res.data.data.list.map(item => item.brand))];
    
    // 创建品牌分组对象
    const brandGroups = uniqueBrands.map(brand => ({
      label: brand,
      value: brand,
      children: res.data.data.list
        .filter(item => item.brand === brand)
        .map(item => ({
          label: item.name,
          value: item.id
        }))
    }));

    return brandGroups;
  }
  else {
    message.error(res.data.message)
  }
}

//子系统选择器
const subSystemSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/sub_system/page',
    method: 'get',
    params:{
      page_num:1,
      page_size:99
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.name,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}


//获取部门信息
const deptSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/organization/department/tree',
    method: 'get',
    params:{
      include_job_titles:0,
      include_accounts:0
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.id,
      children:item.children?item.children.map(children=>({
        label:children.name,
        value:children.id
      })):[]
    }))
  }
  else {
    message.error(res.data.message)
  }
}

//获取用户信息
const userSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/permission/system_user/page',
    method: 'get',
    params:{
      page_num:1,
      page_size:99
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.real_name,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}

Promise.all([
  // assetsTypeSelect(),
  // typeSelect(),
  // assetsStatusSelect(),
  // procurementMethodSelect(),
  selector.storesUnderTheBrand(),
  // assetModelSelect(),
  deptSelect(),
  userSelect(),
  // subSystemSelect()
  ]).then(([
    // assetsTypeSelect,
    // typeSelect,assetsStatusSelect,
    // procurementMethodSelect,
    storesUnderTheBrand,
    // assetModelSelect,
    deptSelect,
    userSelect,
    // subSystemSelect
    ])=>{
  // options.brand = assetsTypeSelect
  // options.type_id = typeSelect
  // options.status = assetsStatusSelect
  // options.procurement_method = procurementMethodSelect
  options.store_id = storesUnderTheBrand
  // options.asset_model_id = assetModelSelect
  // options.model_id = options.asset_model_id
  options.dept_id = deptSelect
  // options.sub_system_id = subSystemSelect
  options.user_id = userSelect
  console.log(options)
}).catch(err=>{
  console.log(err)
  message.error("请求异常，请联系管理员")
})








</script>
<style lang="less" scoped>
@import "@/assets/css/modalTable.css";
@import "@/assets/css/modal.css";
.container {
  width: 100%;
  height: 100%;
}
.searchDivContainer {
  padding-top: 10px;
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
  margin-bottom: 2px;
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-a {
  margin: 2px;
  margin-left: 5px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
.searchItem {
  width: 150px;
  margin-right: 10px;
}

.interfaces-box {
  display: flex;
  .interfaces-item {
    padding: 18px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
  }
  .interfaces-item-no-selected {
    padding: 18px;
    cursor: pointer;
    font-size: 20px;
  }
}
</style>