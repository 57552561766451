<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- 增加门店和主机的绑定关系，查看门店绑定的主机信息-->
<template>
  <a-modal v-model:open="showModal.showBaseStoreMessage"
           title="门店基础信息"
           width="600px"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <table class='table'>
      <tr>
        <td class='column'>品牌</td>
        <td>{{showBaseStoreMessageRef.customer_name}}</td>

      </tr>
      <tr>
        <td class='column'>门店名称</td>
        <td>{{showBaseStoreMessageRef.name}}</td>
      </tr>

      <tr>
        <td class='column'>联系人</td>
        <td>{{showBaseStoreMessageRef.contact_name}}</td>

      </tr>
      <tr>
        <td class='column'>电话</td>
        <td>{{showBaseStoreMessageRef.phone_number}}</td>
      </tr>

      <tr>
        <td class='column'>地址</td>
        <td>{{showBaseStoreMessageRef.address}}</td>
      </tr>
    </table>
    <template #footer>

    </template>
  </a-modal>
  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="showModal.addOrEdit"
           title="SIM卡信息"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 7 }"
              :wrapper-col="{ span: 13
   }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex">
                <a-cascader 
                            v-if="['gateway_id'].includes(item.dataIndex)"
                            v-model:value="baseData[item.dataIndex]"
                            :options="options.gateway_id"
                            :show-search="{ filter }"
                            placeholder="请选择门店"
                            @change="storeChange" />
                 <a-select v-else-if="['technology','type','provider'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          />
              

                <a-input v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
             
              
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

  

  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='container'>
    <TableComponent :columns="fieldAll.columns"
                    :data-source="dataSource.data"
                    :row-selection="rowSelection"
                    :pagination="pagination"
                    @change="handleTableChange"
                    bordered>
      <template #bodyCell="{ column, record }">

        <template v-if="['host'].includes(column.dataIndex)">
          <a class='editable-row-a'
             @click="toWeb(record.web)">{{record.host}}</a>
        </template>
        <template v-if="['names'].includes(column.dataIndex)">
          <div>
            <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
          </div>
        </template>


      
        <template v-if="column.dataIndex === 'operation'">
          <div class="editable-row-operations">
            <a 
               class='editable-row-a'
               @click="editData(record)">编辑</a>
            <a class='editable-row-a' @click="deleteData(record.key)">删除</a>
       
          </div>
        </template>
     

        
      </template>
      <template #title>
        <div class='searchDivContainer'>

          <template v-for="item in searchFields"
                    :key='item.key'>

            <label style='margin-right:5px'>{{item.title}}</label>

            <a-select class='searchItem'
                      v-if="['customer_id','technology','type','provider'].includes(item.key)"
                      :options="options[item.key]"
                      show-search
                      :filter-option="filterOption"
                      v-model:value="searchData[item.key]"
                      allow-clear
                      :placeholder="`请选择${item.title}`"></a-select>
            
            <a-cascader 
                            v-else-if="['store_id'].includes(item.key)"
                            v-model:value="searchData[item.key]"
                            :options="options.store_id"
                            :show-search="{ filter }"
                            placeholder="请选择门店"
                            @change="storeChange" />

            <a-input class='searchItem'
            style="width:240px"
                     v-else
                     v-model:value='searchData[item.key]'
                     :placeholder="`iccid msisdn 门店名称 网关ip`"></a-input>

            <!-- </a-form-item> -->

          </template>

          <!-- <a-select style='width:100px' v-model:value="searchData.field" :options="options.search" defaultValue=''
          placeholder="搜索字段" change-on-select />

        <a-input v-model:value="searchData.input" style="width:150px"></a-input> -->
          <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
          <div class='right-buttons'>
            <a-button class="addDataButton"
                      @click="refresh">刷新</a-button>
            <a-button class="addDataButton"
                      type="primary"
                      @click="addData">添加SIM卡</a-button>

            <a-button class="addDataButton"
                      @click="deleteData">删除数据</a-button>
          </div>
        </div>
      </template>
      <!-- <template #footer>Footer</template> -->
    </TableComponent>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import { useRouter, useRoute } from 'vue-router'
import { commonFun } from '@/utils/commonFun'
import { storeMessage } from '@/utils/commonData'
import dayjs from "dayjs"
import {
  LoadingOutlined
} from '@ant-design/icons-vue';

import serviceAxios from "@/utils/serviceAxios";
import interfaces from "@/config";
import { selector } from "@/utils/selector"
const url = {
  add: '/v1/ops/sim_card/add',
  delete: '/v1/ops/sim_card/delete',
  edit: '/v1/ops/sim_card/edit',
  page: '/v1/ops/sim_card/page',
  refresh: '/v1/ops/sim_card/page',
}
const router = useRouter()

//获取门店信息
const getStoreMessage = (typeId) => {
  storeMessage(typeId).then(result => {
    console.log(result)
    options.storeList = result
  }).catch(error => {
    console.log(error)
  })

}
//确实加载指示符
const spinning = ref(false);


//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})


const searchData = reactive({
  keyword: null,
  customer_id: null,
  store_id: null,
  technology: null,
  type: null,
  provider: null,
  iccid: null,
  msisdn: null,
})

const searchFields = [{
  title: "关键字",
  key: 'keyword'
},

{
  title: "品牌",
  key: 'customer_id'
},
{
  title: "门店",
  key: 'store_id',
  desc: '是否只显示未关联门店: 1.只显示未关联网关的门店， 0. 只显示关联了网关的门店 None.两者'
},
{
  title: "通讯技术",
  key: 'technology'
},
{
  title: "卡类型",
  key: 'type'
},
{
  title: "运营商",
  key: 'provider'
},



]
const getButton = () => {
  let route = useRoute()
  buttonShow = commonFun.getButtonRules(route.fullPath, url)
}
getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [
  {
    title: "品牌",
    dataIndex: "customer_name",
    key: "customer_name",
    ellipsis: true, 
  },
    {
    title: "门店",
    dataIndex: "store_name",
    key: "store_name",
    ellipsis: true, 
  },
  {
    title: "别名",
    dataIndex: "alias",
    key: "alias",
    ellipsis: true, 
  },
  {
    title: "通讯技术",
    dataIndex: "technology",
    key: "technology",
    ellipsis: true, 
  },
  {
    title: "卡类型",
    dataIndex: "type",
    key: "type",
    ellipsis: true, 
  },
  {
    title: "运营商",
    dataIndex: "provider",
    key: "provider",
    ellipsis: true, 
  },
  {
    title: "ICCID",
    dataIndex: "iccid",
    key: "iccid",
    ellipsis: true, 
  },
  {
    title: "NSISDN",
    dataIndex: "msisdn",
    key: "msisdn",
    ellipsis: true, 
  },
  {
    title: "渠道",
    dataIndex: "channel",
    key: "channel",
    ellipsis: true, 
  },
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 300
    }
  ],

  //弹框字段数据
  modalData: [{
    title: "门店",
    dataIndex: "gateway_id",
    key: "gateway_id"
  },
  {
    title: "别名",
    dataIndex: "alias",
    key: "alias"
  },
  {
    title: "通讯技术",
    dataIndex: "technology",
    key: "technology"
  },
  {
    title: "卡类型",
    dataIndex: "type",
    key: "type"
  },
  {
    title: "运营商",
    dataIndex: "provider",
    key: "provider"
  },
  {
    title: "ICCID",
    dataIndex: "iccid",
    key: "iccid"
  },
  {
    title: "NSISDN",
    dataIndex: "msisdn",
    key: "msisdn"
  },
  {
    title: "渠道",
    dataIndex: "channel",
    key: "channel"
  },

  ]
})

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],


})

//增加或者编辑的模态框规则
const rules = {
  gateway_id: [
    {
      required: true,
      message: "请选择网关",
    },
  ],
  technology: [
    {
      required: true,
      message: "通讯技术",
    },
  ],
  type: [
    {
      required: true,
      message: "请选择卡类型",
    },
  ],
  provider: [
    {
      required: true,
      message: "请选择运营商",
    },
  ],
  iccid: [
    {
      required: true,
      message: "请输入iccid",
    },
  ]

}

//选择框数据
const options = reactive({
  technology:[],
  provider:[],
  type:[],
});

// 数据模型
const baseData = reactive({
  id: "",
  gateway_id: 0,
  alias: "",
  technology: "",
  type: "",
  provider: "", 
  iccid: "",
  msisdn: "",
  channel: ""
})

//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  trigger: false,
  bindGateway: false,
  showBaseStoreMessage: false,
  viewInterfacesFlow: false
})



let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = async() => {
  const response = await serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  })
    pagination.total = response.data.data.total;

      dataSource.data = response.data.data.list.map(item => ({
    ...item,
    key: item.id
  }));



}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData['interfacePort'] = 161
};

const formRef = ref()

//编辑数据
const editData = (record) => {
  getStoreMessage(null)
  console.log("record", record);
  urlFlag = url.edit
  showModal.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }
  baseData.gateway_id = [record.customer_name, record.gateway_id]

};


//增加数据
const addData = () => {

  resetForm();
  urlFlag = url.add
  getStoreMessage(0)
  console.log('rowData', rowData)

  //勾选自动填充门店名称 门店ID 品牌等信息
  const selectedFilter = rowData.filter(item => !item.gateway)
  if (selectedFilter.length != 0) {
    const record = selectedFilter[0]
    for (let key in baseData) {
      baseData[key] = record[key]
    }
    baseData['interfacePort'] = 161
    baseData.storeId = [record.item.customer.name, record.item.id]

  }


  showModal.addOrEdit = true;

};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {

  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};





//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      gateway_id: baseData.gateway_id[1],
      alias: baseData.alias,
      technology: baseData.technology,
      type: baseData.type,
      provider: baseData.provider, 
      iccid: baseData.iccid,
      msisdn: baseData.msisdn,
      channel: baseData.channel
    }
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {

      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
        //重置数据选择
        rowData = [];
        selectedRowKey.value = []; // 选中的行的key
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false


    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false

    })


  }
  catch(err) {
    console.log('err', err)
    message.error("请填写完整信息");
  }


};




//搜索
const sift = () => {
  console.log('searchData.freeStore', searchData)

  queryField['technology'] = searchData.technology ? searchData.technology : null;
  queryField['type'] = searchData.type ? searchData.type : null;
  queryField['provider'] = searchData.provider ? searchData.provider : null;
  queryField['store_id'] = searchData.store_id ? searchData.store_id[1] : null;
  queryField['iccid'] = searchData.iccid ? searchData.iccid : null;
  queryField['msisdn'] = searchData.msisdn ? searchData.msisdn : null;
  console.log("queryField", queryField)
  queryPage()
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  for (let key in searchData) {
    searchData[key] = null
  }
  queryPage()
}

const storeChange = (value, selectedOptions) => {

}


const refresh = async () => {
  try {
    const result = await serviceAxios({
      url: url.refresh,
      method: 'get'
    })
    
    if (result.data.message === 'success') {
      message.success('刷新成功')
      await queryPage()
    } else {
      message.error(result.data.message)
    }
  } catch (error) {
    message.error('刷新失败')
    console.error('刷新出错:', error)
  }
}







// 选择框搜索
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
// 级联选择框 搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};


const brandAndGateway = async () => {
  const result = await serviceAxios({
    url: '/v1/ops/customer_and_gateway/tree',
    method: 'get'
  })
  const data = result.data.data.map(item=>({
    label: item.customer_name,
    value: item.customer_name,

    children: item.stores && item.stores.length > 0 ? item.stores.map(child=>({
      label: child.store_name,
      value: child.gateway_id
    })) : []
  }))
  
  return data

}




Promise.all([
  selector.provider(),
  selector.cardType(),
  selector.simTechnology(),
  selector.brand(),
  brandAndGateway(),
  selector.storesUnderTheBrand()
]).then(([provider, cardType, simTechnology, brand, gateway_id, stores]) => {
  console.log('gatewa121212y_id')
  options.provider = provider
  options.type = cardType
  options.technology = simTechnology
  options.customer_id = brand
  options.gateway_id = gateway_id
  options.store_id = stores
  console.log('options',options)
})



</script>
<style lang="less" scoped>
@import "@/assets/css/modalTable.css";
@import "@/assets/css/modal.css";
.container {
  width: 100%;
  height: 100%;
}
.searchDivContainer {
  display: flex;
  align-items: center; 
  justify-content: center;
  padding:10px 0 10px 0;
}

.right-buttons {
  margin-left: auto;
  display: flex;
  align-items: center; 
}

.addDataButton {
  margin: 2px;
  margin-left: 5px; 

}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-a {
  margin: 2px;
  margin-left: 5px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
.searchItem {
  width: 150px;
  margin-right: 10px;
}

.interfaces-box {
  display: flex;
  .interfaces-item {
    padding: 18px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
  }
  .interfaces-item-no-selected {
    padding: 18px;
    cursor: pointer;
    font-size: 20px;
  }
}
</style>