import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";


import HomeView from '../views/HomeView.vue'
import Login from '../views/LoginView.vue'
import LoginTalent from '../views/LoginTalent.vue'
import homeData from '../views/HomeData.vue'
import customer from '../views/synthesis/customer/customer.vue'
import needList from '../views/synthesis/customer/needList.vue'

import trades from '../views/synthesis/program/trades.vue'
import subTrades from '../views/synthesis/program/subTrades.vue'

import NotFound from '../views/NotFound/NotFound.vue' // 需要创建这个组件

import pic from '../views/synthesis/customer/pic.vue'
import pic2 from '../views/synthesis/customer/pic2.vue'
import picOriginal from '../views/synthesis/customer/picOriginal.vue'
import businessList from '../views/synthesis/customer/businessList.vue'
import myBusinessList from '../views/synthesis/customer/myBusinessList.vue'


import aiops from '../views/synthesis/product/aiops.vue'
import productHistory from '../views/synthesis/product/productHistory.vue'
import equipmentLease from '../views/synthesis/product/equipmentLease.vue'
import informationEngineering from '../views/synthesis/product/informationEngineering.vue'
import internetAccess from '../views/synthesis/product/internetAccess.vue'
import service from '../views/synthesis/product/service.vue'
import thirdPartyService from '../views/synthesis/product/thirdPartyService.vue'

//系统管理
import SystemUser from '../views/systemSettings/SystemUser.vue'
import temamtInfo from '../views/systemSettings/temamtInfo.vue'
import jobTitleInfo from '../views/systemSettings/jobTitleInfo.vue'
import departmentInfo from '../views/systemSettings/departmentInfo.vue'
import UserRole from '../views/systemSettings/UserRole.vue'
import EnterHouseStyle from '../views/systemSettings/EnterHouseStyle.vue'
import docTag from '../views/systemSettings/docTag.vue'
import helpCenter from '../views/systemSettings/helpCenter.vue'

import gardenInfo from '../views/synthesis/program/gardenInfo.vue'
import tradeInfo from '../views/synthesis/program/tradeInfo.vue'


import RegionalManagement from '../views/synthesis/RegionalManagement.vue'
import UserSonlist from '../views/users/UserSonlist.vue'

import CreateUser from '../views/users/CreateUser.vue'
import NavigationBar from '../views/auth/NavigationBar.vue'
import NavigationPath from '../views/auth/NavigationPath.vue'
import SystemRule from '../views/auth/SystemRule.vue'
import systemServiceOrders from '../views/tickets/systemServiceOrders.vue'
import addOrder from '../views/tickets/addTicket.vue'
import myOrder from '../views/tickets/myOrder.vue'
import devices from '../views/WarehouseManagement/devices.vue'
import deviceBrand from '../views/WarehouseManagement/deviceBrand.vue'

import talentPool from '../views/tickets/talentPool.vue'
import tagPool from '../views/tickets/tagPool.vue'

//营帐管理
import networkAccessWork from '../views/campManagement/networkAccessWork.vue'
import invoiceTypeList from '../views/campManagement/invoiceTypeList.vue'
import revenueRecord from '../views/campManagement/revenueRecord.vue'
import contractRevenueRecord from '../views/campManagement/contractRevenueRecord.vue'
import financeAccountList from '../views/campManagement/financeAccountList.vue'
import businessCluster from '../views/campManagement/businessCluster.vue'

//aips
import hostList from '../views/aiops/hostList.vue'
import gatewayList from '../views/aiops/gatewayList.vue'
import map from '../views/aiops/map.vue'
import SimCard from '../views/aiops/SimCard.vue'

//点秀
import pointShow from '../views/pointShow/pointShow.vue'


//无数据模板
import resultComponent from '../components/resultComponent.vue'

//测试
// import demo from '../views/users/demo.vue'
// import homePage from '../views/demo/HomeViewDemo.vue'
// import loginView from '../views/demo/LoginViewDemo.vue'
// import homePageData from '../views/demo/HomeDataDemo.vue'
// import glopt from '../views/demo/glopt.vue'
// import storeDetailPage from '../views/demo/storeDetailPage.vue'
// import storeMessage from '../views/demo/storeMessage.vue'
// import customerView from '../views/demo/customerView.vue'
// import ticketView from '../views/demo/ticketView.vue'
// import addTicket from '../views/demo/addTicket.vue'
// import pointShow from '../views/demo/pointShow.vue'
// import accountManage from '../views/demo/accountManage.vue'
// import customerTemperatureSensorList from '../views/demo/sensorList.vue'
// import customeriotStoreConfig from '../views/demo/iotStoreConfig.vue'
// import sensorLogList from '../views/demo/sensorLogList.vue'
// import sensorDetails from '../views/demo/sensorDetails.vue'
// import customerWorkOrder from '../views/demo/workOrder.vue'
// import energyDetailPage from '../views/demo/energyDetailPage'
// import customerEnergySensorList from '../views/demo/energySensorList'

import test from '../views/test.vue'

//iot

import iotHome from '../views/iot/homeView.vue'
import temperatrueControlHost from '../views/iot/temperatrueControlHost.vue'
import iotTemperatureSensorList from '../views/iot/temperatureSensorList.vue'
import iotServiceConfig from '../views/iot/iotServiceConfig.vue'
import iotStoreConfig from '../views/iot/iotStoreConfig.vue'
import iotEnergySensorList from '../views/iot/energySensorList.vue'

//资产
import AssetsType from '../views/assets/AssetsType.vue'
import AssetsModel from '../views/assets/AssetsModel.vue'
import AssetsSubSystem from '../views/assets/AssetsSubSystem.vue'
import AssetsList from '../views/assets/AssetsList.vue'
import AssetsUsageRecords from '../views/assets/AssetUsageRecords.vue'
import AssetsGlobalConfig from '../views/assets/AssetsGlobalConfig.vue'
import AssetsTransferRecord from '../views/assets/AssetsTransferRecord.vue'
import AssetsScrapRecord from '../views/assets/AssetsScrapRecord.vue'
import AssetsRepairRecord from '../views/assets/AssetsRepairRecord.vue'
import AssetsScrapMethod from '../views/assets/AssetsScrapMethod.vue'
const routes = [
  {
    path: '/pointShow',
    component: pointShow,
    meta: { breadcrumb: '信发系统' },
  },
  {
    // 登录
    path: '/login',
    component: Login,
    // redirect: '/welcome',
    children: [

    ]

  },

  {
    // 人才登录
    path: '/loginTalent',
    component: LoginTalent,
    // redirect: '/welcome',
    children: [

    ]
  },

  // {
  //   // 测试
  //   path: '/loginView',
  //   component: loginView,
  //   // redirect: '/welcome',
  //   children: [

  //   ]
  // },
  {
    path: '/iotHome',
    component: iotHome,
    // redirect: '/welcome',
    children: [

    ]
  },

  // {
  //   path: '/homePage',
  //   component: homePage,
  //   redirect: '/homePageData',
  //   meta: { breadcrumb: '首页' },
  //   children: [{
  //     path: '/homePageData',
  //     component: homePageData,
  //     meta: { breadcrumb: '首页数据概括' },


  //   },
  //   {
  //     path: '/customerWorkOrder',
  //     component: customerWorkOrder,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/energyDetailPage',
  //     component: energyDetailPage,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/customerEnergySensorList',
  //     component: customerEnergySensorList,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/customerTemperatureSensorList',
  //     component: customerTemperatureSensorList,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/customeriotStoreConfig',
  //     component: customeriotStoreConfig,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/sensorLogList',
  //     component: sensorLogList,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/sensorDetails',
  //     component: sensorDetails,
  //     // redirect: '/welcome',
  //     children: [

  //     ]
  //   },
  //   {
  //     path: '/glopt',
  //     component: glopt,
  //     meta: { breadcrumb: '拓扑图' },
  //   }, {
  //     path: '/customerView',
  //     component: customerView,
  //     meta: { breadcrumb: '门店信息' },
  //   }, {
  //     path: '/storeDetailPage',
  //     component: storeDetailPage,
  //     meta: { breadcrumb: '门店网络详情' },
  //   }, {
  //     path: '/storeMessage',
  //     component: storeMessage,
  //     meta: { breadcrumb: '门店数据' },
  //   },
  //   {
  //     path: '/ticketView',
  //     component: ticketView,
  //     meta: { breadcrumb: '工单列表' },
  //   },
  //   {
  //     path: '/accountManage',
  //     component: accountManage,
  //     meta: { breadcrumb: '账户管理' },
  //   },
  //   {
  //     path: '/addTicket',
  //     component: addTicket,
  //     meta: { breadcrumb: '添加工单' },
  //   },



  //   ]
  // },


  {
    path: '/pic',
    component: pic,
    meta: { breadcrumb: '拓扑图' },
  },
  {
    path: '/pic2',
    component: pic2,
    meta: { breadcrumb: '拓扑图' },
  },
  {
    path: '/test',
    component: test,
    meta: { breadcrumb: '测试' },
  },

  {
    path: '/home',
    component: HomeView,
    redirect: '/homeData',
    meta: { breadcrumb: '首页' },
    children: [



      {
        path: '/homeData',
        component: homeData,
        meta: { breadcrumb: '数据概览' },
      },

      {
        path: '/result',
        component: resultComponent,
        meta: { breadcrumb: '结果' },
      },


      {
        path: '/picOriginal',
        component: picOriginal,
        meta: { breadcrumb: '拓扑图' },
      },

      //营帐管理
      {
        path: '/yingzhang',
        // component: HomeView,
        redirect: '/revenueRecord',
        meta: { breadcrumb: '营账管理' },
        children: [
          {
            path: '/networkAccessWork',
            component: networkAccessWork,
            meta: { breadcrumb: '网络接入成本列表' },
          },

          {
            path: '/invoiceTypeList',
            component: invoiceTypeList,
            meta: { breadcrumb: '发票类型列表' },
          },
          {
            path: '/revenueRecord',
            component: revenueRecord,
            meta: { breadcrumb: '收款记录' },
          },
          {
            path: '/contractRevenueRecord',
            component: contractRevenueRecord,
            meta: { breadcrumb: '合同收款记录' },
          },
          {
            path: '/financeAccountList',
            component: financeAccountList,
            meta: { breadcrumb: '财务账户列表' },
          },
        ]
      },
      //资产
      {
        path: '/assetsType',
        component: AssetsType,
        meta: { breadcrumb: '资产类型' },
      },
      {
        path: '/assetsList',
        component: AssetsList,
        meta: { breadcrumb: '资产列表' },
      },
      {
        path: '/assetsModel',
        component: AssetsModel,
        meta: { breadcrumb: '资产型号' },
      },
      {
        path: '/assetsSubSystem',
        component: AssetsSubSystem,
        meta: { breadcrumb: '资产子系统' },
      },
      {
        path: '/assetsUsageRecords',
        component: AssetsUsageRecords,
        meta: { breadcrumb: '资产使用记录' },
      },
      {
        path: '/assetsGlobalConfig',
        component: AssetsGlobalConfig,
        meta: { breadcrumb: '资产全局配置' },
      },
      {
        path: '/assetsTransferRecord',
        component: AssetsTransferRecord,
        meta: { breadcrumb: '资产调拨记录' },
      },
      {
        path: '/assetsScrapRecord',
        component: AssetsScrapRecord,
        meta: { breadcrumb: '资产报废记录' },
      },
      {
        path: '/assetsScrapMethod',
        component: AssetsScrapMethod,
        meta: { breadcrumb: '资产报废方式' },
      },
      {
        path: '/assetsRepairRecord',
        component: AssetsRepairRecord,
        meta: { breadcrumb: '资产维修记录' },
      },

      //AIOps智能管理平台
      {
        path: '/aiopsSystem',
        redirect: '/hostList',
        meta: { breadcrumb: 'AIOps智能管理平台' },
        children: [
          {
            path: '/hostList',
            component: hostList,
            meta: { breadcrumb: '设备关联' },
          },
          {
            path: '/iotGatewayList',
            component: gatewayList,
            meta: { breadcrumb: '网关列表' },
          },
          {
            path: '/SimCard',
            component: SimCard,
            meta: { breadcrumb: 'SIM卡列表' },
          },

          {
            path: '/map',
            component: map,
            meta: { breadcrumb: '地图' },
          },


        ]
      },
      //iot
      {
        path: '/anythingconnect',
        redirect: '/temperatrueControlHost',
        meta: { breadcrumb: '物联网管理平台' },
        children: [{
          path: '/temperatrueControlHost',
          component: temperatrueControlHost,
          meta: { breadcrumb: '温控主机列表' },
        },
        {
          path: '/iotTemperatureSensorList',
          component: iotTemperatureSensorList,
          meta: { breadcrumb: '温控传感器列表' },
        },
        {
          path: '/iotEnergySensorList',
          component: iotEnergySensorList,
          meta: { breadcrumb: '电量传感器列表' },
        },
        {
          path: '/iotServiceConfig',
          component: iotServiceConfig,
          meta: { breadcrumb: '物联网服务配置' },
        },
        {
          path: '/iotStoreConfig',
          component: iotStoreConfig,
          meta: { breadcrumb: '门店配置' },
        },


        ]

      },
      {
        path: '/businessCluster',
        component: businessCluster,
        meta: { breadcrumb: '商圈' },
      },



      {
        path: '/customer',
        component: customer,
        meta: { breadcrumb: '品牌客户' },
      },
      {
        path: '/needList',
        component: needList,
        meta: { breadcrumb: '商机需求项管理' },
      },

      {
        path: '/aiops',
        component: aiops,
        meta: { breadcrumb: 'AIOps Service' },
      },
      {
        path: '/productHistory',
        component: productHistory,
        meta: { breadcrumb: '产品模板' },
      },
      {
        path: '/businessList',
        component: businessList,
        meta: { breadcrumb: '品牌信息库' },

      },
      {
        path: '/myBusinessList',
        component: myBusinessList,
        meta: { breadcrumb: '我的商机' },

      },

      {
        path: '/subTrades',
        component: subTrades,
        meta: { breadcrumb: '子行业' },

      },
      {
        path: '/trades',
        component: trades,
        meta: { breadcrumb: '行业管理' },

      },


      {
        path: '/gardenInfo',
        component: gardenInfo,

      },
      {
        path: '/tradeInfo',
        component: tradeInfo,
        meta: { breadcrumb: '项目品牌' },

      },



      {
        path: '/regionalM',
        component: RegionalManagement,

      },

      {
        path: '/contract_user_info',
        component: UserSonlist,
        meta: { breadcrumb: '门店信息管理' },

      },
      {
        path: '/CreateUser',
        component: CreateUser,

      },

      // CreateUser

      {
        path: '/enterHouseStyle',
        component: EnterHouseStyle,
        meta: { breadcrumb: '入户方式' },


      },
      {
        path: '/docTag',
        component: docTag,
        meta: { breadcrumb: '文档标签' },


      },
      {
        path: '/helpCenter',
        component: helpCenter,
        meta: { breadcrumb: '帮助文档中心' },


      },
      {
        path: '/systemuser',
        component: SystemUser,
        meta: { breadcrumb: '系统用户管理' },

      },
      {
        path: '/temamtInfo',
        component: temamtInfo,
        meta: { breadcrumb: '租户管理' },

      },
      {
        path: '/jobTitleInfo',
        component: jobTitleInfo,
        meta: { breadcrumb: '职位管理' },

      },
      {
        path: '/departmentInfo',
        component: departmentInfo,
        meta: { breadcrumb: '部门管理' },


      },
      {
        path: '/navigationBar',
        component: NavigationBar,
        meta: { breadcrumb: '导航栏管理' },

      },
      {
        path: '/navigationPath',
        component: NavigationPath,
      },
      {
        path: '/role',
        component: UserRole,
        meta: { breadcrumb: '角色管理' },
      },

      {
        path: '/systemRule',
        component: SystemRule,
        meta: { breadcrumb: '系统权限规则' },

      },
      {
        path: '/systemServiceOrders',
        name: 'systemServiceOrders',
        component: systemServiceOrders,
        meta: { breadcrumb: '工单池' },

      },
      {
        path: '/addOrder',
        component: addOrder,
        meta: { breadcrumb: '新建工单' },

      },
      {
        path: '/devices',
        component: devices,
        meta: { breadcrumb: '设备管理' },

      },
      {
        path: '/deviceBrand',
        component: deviceBrand,
        meta: { breadcrumb: '设备品牌管理' },

      },
      {
        path: '/talentPool',
        component: talentPool,
        meta: { breadcrumb: '人才库' },

      },
      {
        path: '/tagPool',
        component: tagPool,
        meta: { breadcrumb: '标签管理' },

      },
      {
        path: '/myOrder',
        component: myOrder,
        meta: { breadcrumb: '我的工单' },

      },




    ]
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
    meta: { breadcrumb: '404 Not Found' }
  }
]
const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router