<!-- eslint-disable vue/valid-v-else-if -->
<!-- eslint-disable vue/no-multiple-template-root -->
<!-- eslint-disable vue/no-v-for-template-key -->
<!-- eslint-disable vue/no-v-model-argument -->
<!-- 增加门店和主机的绑定关系，查看门店绑定的主机信息-->
<template>

  <!-- 添加或者删除数据弹窗 -->
  <a-modal width="800px"
           v-model:open="showModal.addOrEdit"
           title="资产维修记录"
           ok-text="确认"
           cancel-text="取消"
           @ok="addOrEditHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 13
   }"
              :rules="rules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.modalData"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex"
                           >
                  <!-- <a-cascader 
                            v-if="['new_store_id'].includes(item.dataIndex)"
                            v-model:value="baseData[item.dataIndex]"
                            :options="options[item.dataIndex]"
                            :show-search="{ filter }"
                            :placeholder="`请选择${item.title}`"
                             /> -->
                  <a-select v-if="['method','new_store_id','approver_id','asset_id'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          />

                  <a-select v-else-if="['express_provider'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :disabled="baseData.method!='快递'"
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          />
                <a-config-provider :locale="locale" v-else-if="['start_time','end_time'].includes(item.key)">
                  <a-date-picker show-time placeholder="请选择开始时间"  v-model:value="baseData[item.key]"  @change="onChange" @ok="onOk"  format="YYYY-MM-DD HH:mm:ss"  valueFormat='YYYY-MM-DD HH:mm:ss'/>
            </a-config-provider>
                  <a-input v-else-if="['express_sn'].includes(item.dataIndex)"
                                v-model:value="baseData[item.dataIndex]"
                                :disabled="baseData.method!='快递'"
                                :placeholder="`请输入${item.title}`" />
                  <a-textarea  v-else-if="['repair_content'].includes(item.dataIndex)"  
                   v-model:value="baseData[item.dataIndex]" 
                    :placeholder="`请输入${item.title}`" :rows="4" />
                  <a-tree-select
                    v-else-if="['new_dept_id'].includes(item.dataIndex)"
                    v-model:value="baseData[item.dataIndex]"
                    show-search
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :placeholder="`请选择${item.title}`"
                    allow-clear
                    tree-default-expand-all
                    :tree-data="options.new_dept_id"
                    tree-node-filter-prop="label"
                  ></a-tree-select>
                <a-input-number v-else-if="['gateway_port'].includes(item.dataIndex)"
                                v-model:value="baseData['gateway_port']"
                                :placeholder="`请输入${item.title}`" />
                <a-input 
                         v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>

    <!-- 关闭维修记录 -->
  <a-modal width="800px"
           v-model:open="showModal.closeRepairRecord"
           title="关闭维修记录"
           ok-text="确认"
           cancel-text="取消"
           @ok="closeRepairRecordHandleOk"
           :maskClosable="false">
    <a-spin :spinning="spinning">
      <a-form :model="baseData"
              ref="closeRepairRecordFormRef"
              :label-col="{ span: 10 }"
              :wrapper-col="{ span: 13
   }"
              :rules="closeRepairRecordRules">
        <a-row :gutter="24">
          <template v-for="item in fieldAll.closeRepairRecord"
                    :key="item.key">
            <a-col :span="12">
              <a-form-item :label="item.title"
                           :name="item.dataIndex"
                           >
                  <!-- <a-cascader 
                            v-if="['new_store_id'].includes(item.dataIndex)"
                            v-model:value="baseData[item.dataIndex]"
                            :options="options[item.dataIndex]"
                            :show-search="{ filter }"
                            :placeholder="`请选择${item.title}`"
                             /> -->
                  <a-select v-if="['success'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          />

                  <a-select v-else-if="['express_provider'].includes(item.dataIndex)"
                          v-model:value="baseData[item.dataIndex]"
                          :disabled="baseData.method!='快递'"
                          :notFoundContent="`无${item.title}`"
                          :options="options[item.dataIndex]"
                          show-search
                          :filter-option="filterOption"
                          :placeholder="`请选择${item.title}`"
                          />
                          
                <a-config-provider :locale="locale" v-else-if="['start_time','end_time'].includes(item.key)">
                  <a-date-picker show-time placeholder="请选择开始时间"  v-model:value="baseData[item.key]"  @change="onChange" @ok="onOk"  format="YYYY-MM-DD HH:mm:ss"  valueFormat='YYYY-MM-DD HH:mm:ss'/>
            </a-config-provider>
                  <a-input v-else-if="['express_sn'].includes(item.dataIndex)"
                                v-model:value="baseData[item.dataIndex]"
                                :disabled="baseData.method!='快递'"
                                :placeholder="`请输入${item.title}`" />
                  <a-textarea  v-else-if="['repair_result'].includes(item.dataIndex)"  
                   v-model:value="baseData[item.dataIndex]" 
                    :placeholder="`请输入${item.title}`" :rows="4" />
                  <a-tree-select
                    v-else-if="['new_dept_id'].includes(item.dataIndex)"
                    v-model:value="baseData[item.dataIndex]"
                    show-search
                    style="width: 100%"
                    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                    :placeholder="`请选择${item.title}`"
                    allow-clear
                    tree-default-expand-all
                    :tree-data="options.new_dept_id"
                    tree-node-filter-prop="label"
                  ></a-tree-select>
                <a-input-number v-else-if="['gateway_port'].includes(item.dataIndex)"
                                v-model:value="baseData['gateway_port']"
                                :placeholder="`请输入${item.title}`" />
                <a-input 
                         v-else
                         v-model:value="baseData[item.dataIndex]"
                         :placeholder="`请输入${item.title}`" />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>


  <!-- 确认删除弹窗 -->
  <a-modal :maskClosable="false"
           v-model:open="showModal.isDeleteModalVisible"
           title="确认删除"
           @ok="handleOkDelete">
    <p>您确定要删除数据吗？此操作不可逆。</p>
  </a-modal>
  <div class='container'>
    <div class='sensor-detail-modal'
         v-if="showModal.viewInterfacesFlow">
      <div class='message-box'>
        <img @click='closeModal'
             class='close-image'
             src='@/static/common/关闭.png' />
 
      </div>
    </div>
    <TableComponent :columns="fieldAll.columns"
                    :data-source="dataSource.data"
                    :row-selection="rowSelection"
                    :pagination="pagination"
                    @change="handleTableChange"
                    bordered>
      <template #bodyCell="{ column, record }">

        <template v-if="['host'].includes(column.dataIndex)">
          <a class='editable-row-a'
             @click="toWeb(record.web)">{{record.host}}</a>
        </template>
        <template v-if="['names'].includes(column.dataIndex)">
          <div>
            <a @click="showUserMessage(record.name,record.key)">{{ record.name }}</a>
          </div>
        </template>

        <template v-if="['operationalStatus'].includes(column.dataIndex)">
          <div class="status-light">
            <a @click="operationalStatusClick(record.id)"
               v-if='record.operationalStatus'
               class="green-light"></a>
            <a @click="operationalStatusClick(record.id)"
               v-if='!record.operationalStatus'
               class="red-light"></a>

          </div>
        </template>
        <template v-else-if="column.dataIndex === 'operation'">
          <div class="editable-row-operations">
             <a 
               class='editable-row-a'
               v-if="record.transfer_status=='维修中'"
               @click="closeRepairRecord(record)">关闭维修记录</a>
            <a 
               class='editable-row-a'
               @click="editData(record)">编辑</a>
            <a class='editable-row-a' @click="deleteData(record.key)">删除</a>
    
           
          </div>
        </template>

      </template>
      <template #title>
         <div class='searchDivContainer'>
          <a-form :model="baseData"
              ref="formRef"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 13
   }">
          <a-row :gutter="24">
          <template   v-for="item in searchFields"
                    :key='item.key'>
            <a-col :span="4">

            <a-form-item :label="item.title"
                           :name="item.dataIndex">
            <a-tree-select class='searchItem'
                           v-if='["dept_id"].includes(item.key)'
                           v-model:value="searchData[item.key]"
                           show-search
                           :placeholder="`请选择${item.title}`"
                           allow-clear
                           :tree-data="options[item.key]"
                           tree-node-filter-prop="label"></a-tree-select>
            <a-select class='searchItem'
                      v-else-if='["asset_id","need_approval","approver_id","type_id","type_id","sub_system_id","method","status","creator_id","approver_id"].includes(item.key)'
                      :options="options[item.key]"
                      v-model:value="searchData[item.key]"
                      allow-clear
                      show-search
                       :filter-option="filterOption"
                      :placeholder="`请选择${item.title}`"></a-select>
            <a-config-provider :locale="locale" v-else-if="['start_time','end_time'].includes(item.key)">
                  <a-date-picker show-time placeholder="请选择开始时间"  v-model:value="searchData[item.key]"  @change="onChange" @ok="onOk"  format="YYYY-MM-DD HH:mm:ss"  valueFormat='YYYY-MM-DD HH:mm:ss'/>
            </a-config-provider>
             <a-cascader 
                            v-else-if="['store_id','model_id'].includes(item.key)"
                            v-model:value="searchData[item.key]"
                            :options="options[item.key]"
                            :show-search="{ filter }"
                            :placeholder="`请选择${item.title}`"
                             />
              
            <a-input class='searchItem'
                     v-else
                     v-model:value='searchData[item.key]'
                     :placeholder="`请输入${item.title}`"></a-input>
              </a-form-item>
          </a-col>

            <!-- </a-form-item> -->
          </template>
          <a-col :span="4">
            <a-button style="margin:2px"
                    type="primary"
                    @click="sift">搜索</a-button>
          <a-button style="margin:2px"
                    @click="resetSift">重置</a-button>
            <a-button class="addDataButton"
                     style="margin:2px"
                      @click="refresh">刷新</a-button>
          </a-col>
                  <div style='margin-left:auto;margin-right:45px'>
             <a-button
                     style="margin:2px"
                     type="primary"
                      @click="addData">新增</a-button>
                       <a-button 
                     style="margin:2px"
                      @click="deleteData">删除</a-button>
          </div>
          </a-row>
  
          </a-form>
       
          
        </div>
      </template>
      <!-- <template #footer>Footer</template> -->
    </TableComponent>
  </div>
</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import * as echarts from "echarts";
import serviceAxios from "@/utils/serviceAxios";
import { message } from "ant-design-vue";
import { reactive, ref, h } from "vue";
import interfaces from "@/config";
import { useRouter, useRoute } from 'vue-router'
import { commonFun } from '@/utils/commonFun'
import { storeMessage } from '@/utils/commonData'
import { selector } from '@/utils/selector'
import dayjs from "dayjs"
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const locale = zhCN;
import {
  LoadingOutlined
} from '@ant-design/icons-vue';
const url = {
  add: '/v1/asset/repair_record/add',
  delete: '/v1/asset/repair_record/delete',
  edit: '/v1/asset/repair_record/edit',
  page: '/v1/asset/repair_record/page',
  refresh: '/v1/asset/repair_record/page',
}
const router = useRouter()


//确实加载指示符
const spinning = ref(false);
const assetIssueSpinning = ref(false);

//按钮的显示 
let buttonShow = reactive({
  add: true,
  delete: true,
  edit: true,
  page: true,
})

//资产发放数据模型
const assetIssueState = ref({
  asset_id: 0,
  user_id: [],
  start_time: '',
  force_withdraw: 0,
})
//资产回收数据模型
const assetRecycleState = ref({
  asset_id: 0,
  asset_sn: []
})
//搜索数据模型
const searchData = reactive({
  keyword: null,

})

const searchFields = [
  {
  title: "关键字",
  key: 'keyword'
},
  {
  title: "资产",
  key: 'asset_id'
},
  {
  title: "资产SN",
  key: 'asset_sn'
},
  {
  title: "资产调拨记录ID",
  key: 'id'
},
  {
  title: "是否需要审批",
  key: 'need_approval'
},
    {
    title: "资产型号",
    key: 'model_id'
  },
    {
    title: "资产类型",
    key: 'type_id'
  },
    {
    title: "子系统",
    key: 'sub_system_id'
  },
      {
    title: "物流方式",
    key: 'method'
  },
     {
    title: "调拨状态",
    key: 'status'
  },
       {
    title: "开始时间",
    key: 'start_time'
  },
       {
    title: "结束时间",
    key: 'end_time'
  },
        {
    title: "创建人",
    key: 'creator_id'
  },
        {
    title: "审批人",
    key: 'approver_id'
  },



]

const getButton = () => {
  let route = useRoute()
  buttonShow = commonFun.getButtonRules(route.fullPath, url)
}
getButton()


const fieldAll = reactive({
  // table列名数据
  columns: [
    {
      title: "资产编号",
      dataIndex: "asset_sn",
      key: "asset_sn"
    },
    {
      title: "资产名称",
      dataIndex: "asset_alias",
      key: "asset_alias",
      ellipsis: true,
    },
                {
      title: "维修状态",
      dataIndex: "transfer_status",
      key: "transfer_status",
      ellipsis: true,
    },
    //             {
    //   title: "方式",
    //   dataIndex: "method",
    //   key: "method",
    //   ellipsis: true,
    // },
        {
      title: "子系统",
      dataIndex: "sub_system_name",
      key: "sub_system_name",
      ellipsis: true,
    },
    {
      title: "类型",
      dataIndex: "type_name",
      key: "type_name",
      ellipsis: true,
    },
       {
      title: "品牌",
      dataIndex: "model_brand",
      key: "model_brand",
      ellipsis: true,
    },
    {
      title: "型号",
      dataIndex: "model_name",
      key: "model_name",
      ellipsis: true,
    },
        {
      title: "创建人",
      dataIndex: "creator_name",
      key: "creator_name",
      ellipsis: true,
    },
    //     {
    //   title: "调拨人",
    //   dataIndex: "approver_name",
    //   key: "approver_name",
    //   ellipsis: true,
    // },
 
    {
      title: "操作",
      dataIndex: "operation",
      key: "operation",
      width: 300
    }
  ],

  //弹框字段数据
  modalData: [  
     {
      title: "资产",
      dataIndex: "asset_id",
      key: "asset_id",

    },
      {
      title: "开始时间",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "故障原因和维修内容",
      dataIndex: "repair_content",
      key: "repair_content",
    },
   
  ],
   closeRepairRecord:[
       {
      title: "是否成功",
      dataIndex: "success",
      key: "success",
    },
        {
      title: "结束时间",
      dataIndex: "end_time",
      key: "end_time",
    },
    
     {
      title: "维修结果",
      dataIndex: "repair_result",
      key: "repair_result",
    },
  ]
})

//table数据源
const dataSource = reactive({
  //table的基础数据
  data: [],


})

//增加或者编辑的模态框规则
const rules = {
  asset_id: [
    {
      required: true,
      message: "请选择资产",
    },
  ],
   repair_content: [
    {
      required: true,
      message: "请输入故障原因和维修内容",
    },
  ],

}

const closeRepairRecordRules = {
  success: [
    {
      required: true,
      message: "请选择是否成功",
    },
  ],
  repair_result: [
    {
      required: true,
      message: "请输入维修结果",
    },
  ],
}

//选择框数据
const options = reactive({
  
  brand: [],
  type_id: [],
  is_active:[{label:'否',value:0},{label:'是',value:1}],
  value:[{label:'否',value:0},{label:'是',value:1}],
  need_approval:[{label:'否',value:0},{label:'是',value:1}],
});

// 数据模型
const baseData = reactive({
  id: "",
  asset_id: 0, 
  repair_content:'', 
  start_time: '',

})


//弹框显示
const showModal = reactive({
  addOrEdit: false,
  isDeleteModalVisible: false,
  closeRepairRecord: false,

})




let urlFlag = "" //判断是增加数据还是编辑数据


const pagination = reactive({
  current: 1, // 当前页码
  pageSize: 10, // 每页显示数量
  total: 10, // 数据总数（从后端获取）
  // showSizeChanger: true,  //是否显示每页显示个数选择器
  pageSizeOptions: ["10", "20", "50", "100"], // 指定每页可以显示个数
  showQuickJumper: true, // 是否可以快速跳转至某页
  showTotal: total => `总数：${pagination.total} 条`
});

// 处理表格分页变化
const handleTableChange = async (paginationNew) => {
  const { current, pageSize } = paginationNew;
  pagination.current = current;
  pagination.pageSize = pageSize;
  queryField.page_num = current;
  queryField.page_size = pageSize;
  queryPage(); //刷新数据
};


let rowData = [];
let selectedRowKey = ref([]); // 选中的行的key
// 选择框
const rowSelection = reactive({
  selectedRowKeys: selectedRowKey,
  checkStrictly: false,
  onChange: (selectedRowKeys, selectedRows) => {
    selectedRowKey.value = selectedRowKeys;
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
    rowData = JSON.parse(JSON.stringify(selectedRows)); //json 序列化
    console.log("rowData", rowData);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    rowData = JSON.parse(JSON.stringify(selectedRows));
    console.log(selected, selectedRows, changeRows);
  },
});

let queryField = reactive({
  page_num: pagination.current,
  page_size: pagination.pageSize,
})

const queryPage = async () => {
  const res = await serviceAxios({
    url: url.page,
    method: 'get',
    params: queryField
  })
    pagination.total = res.data.data.total;

    // 更新表格数据

    dataSource.data = res.data.data.list.map(item=>({
      ...item,
      key:item.record_id,
      id:item.record_id
     
    }))




}
queryPage()
//重置数据模型
const resetForm = () => {
  for (let key in baseData) {
    // console.log(key);
    baseData[key] = null;
  }
  baseData['interfacePort'] = 161
};

const formRef = ref()
const closeRepairRecordFormRef = ref()
const editFlag = ref(false)
//编辑数据
const editData = async(record) => {
  editFlag.value = true;
  console.log("record", record);
  urlFlag = url.edit;
  showModal.addOrEdit = true;
  for (let key in baseData) {
    baseData[key] = record[key]
  }

};


const getAssetModelBrand = async (assetModelId)=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_model/page',
    method: 'get',
    params: {
      id: assetModelId
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list[0].brand
  }
  else {
    message.error(res.data.message)
  }
}

//增加数据
const addData = () => {
  editFlag.value = false

  resetForm();
  urlFlag = url.add

  //勾选自动填充门店名称 门店ID 品牌等信息
  baseData.is_active = 0
  baseData.value = 0


  showModal.addOrEdit = true;

};
//判断是勾选删除还是点击删除
let deleteFlag = reactive({
  flag: "",
  data: ""
})
//删除数据
const deleteData = (data) => {

  console.log('deleteData', data, typeof (data))
  if (typeof (data) == 'number') {
    showModal.isDeleteModalVisible = true;
    deleteFlag.flag = "single"
    deleteFlag.data = data
  }
  else {
    if (selectedRowKey.value.length === 0) {
      message.error("请至少勾选一条数据");
    } else {
      showModal.isDeleteModalVisible = true;
      deleteFlag.flag = "much"
    }
  }

};


const handleOkDelete = () => {
  // 执行删除操作
  // console.log("执行删除操作");
  // 删除操作完成后，关闭模态框
  let ids = [];
  if (deleteFlag.flag == 'single') {
    ids = [deleteFlag.data]
  }
  else if (deleteFlag.flag == 'much') {
    rowData.forEach((item) => {
      ids.push(item.id);
    });
  }
  serviceAxios({
    url: url.delete,
    method: "post",
    data: {
      ids: ids,
    },
  })
    .then(
      (result) => {
        if (result.data.message == 'success') {
          queryPage()
          message.success("删除成功")
          selectedRowKey.value = []
        }
        else {
          message.error(result.data.message);
        }
      }

    )
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
  showModal.isDeleteModalVisible = false;
};


//提交
const addOrEditHandleOk = async () => {
  console.log(baseData);
  try {
    await Promise.all([formRef.value.validate()])
    let data = {
      id: baseData.id,
      asset_id: baseData.asset_id,
      repair_content: baseData.repair_content,
      start_time: baseData.start_time,
    }
    data =  Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null));
    spinning.value = true
    serviceAxios({
      url: urlFlag,
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {

      if (result.data.message == 'success') {
        message.success("成功")
        showModal.addOrEdit = false
        queryPage()
        //重置数据选择
        rowData = [];
        selectedRowKey.value = []; // 选中的行的key
      }
      else {
        message.info(result.data.message)
      }
      spinning.value = false
    }).catch(err => {
      console.log(err)
      message.error("接口超时")
      spinning.value = false
    })
  }
  catch(error) {
    console.log(error)
    message.error("请填写完整信息");
  }
};


//定义关闭维修记录数据模型
const closeRepairRecordData = reactive({
  id: 0,
  repair_result:'',
  end_time:'',
  success:0
})

const closeRepairRecord = (record)=>{
  showModal.closeRepairRecord = true
  baseData.id = record.id
  baseData.asset_id = record.asset_id
  baseData.repair_content = record.repair_content
  baseData.start_time = record.start_time
}

//关闭维修记录
const closeRepairRecordHandleOk = async ()=>{
  console.log(baseData);
  try {
      await Promise.all([closeRepairRecordFormRef.value.validate()])

    let data = {
      id: baseData.id,
      repair_result: baseData.repair_result,
      end_time: baseData.end_time,
      success: baseData.success,
    }
    data =  Object.fromEntries(Object.entries(data).filter(([_, v]) => v !== null));
    serviceAxios({
      url: '/v1/asset/repair_record/close',
      method: "post",
      timeout: 30000,
      data: data
    }).then(result => {

      if (result.data.message == 'success') {
        message.success("成功")
        showModal.closeRepairRecord = false
        queryPage()

      }
      else {
        message.info(result.data.message)
      }
    }).catch(err => {
      console.log(err)
      message.error("接口超时")
    })
  }
  catch(error) {
    console.log(error)
    message.error("请填写完整信息");
  }

}


//搜索
const sift = () => {
  console.log('searchData.freeStore', searchData)
   for (let key in searchData) {
    queryField[key] = searchData[key]
  }
  queryField['model_id'] = queryField['model_id']?queryField['model_id'][1]:null
  queryPage()
  message.info("搜索完成")
}

//重置搜索
const resetSift = () => {
  queryField = {
    page_num: 1,
    page_size: pagination.pageSize,
  }
  for (let key in searchData) {
    searchData[key] = null
  }
  queryPage()
}





const storeChange = (value, selectedOptions) => {
  console.log(value, selectedOptions)
  baseData['brand'] = selectedOptions[0].label
  baseData['storeName'] = selectedOptions[1].label
  // baseData.storeId = selectedOptions[1].value
}


const refresh = () => {
  serviceAxios({
    url: url.refresh,
    method: 'get'

  }).then(
    result => {
      if (result.data.message == 'success') {
        message.success('刷新成功')
        queryPage()
      }
      else {
        message.error(result.data.message)
      }
    }
  )
}







// 选择框搜索
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
// 级联选择框 搜索
const filter = (inputValue, path) => {
  return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
};



//获取部门信息
const deptSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/organization/department/tree',
    method: 'get',
    params:{
      include_job_titles:0,
      include_accounts:0
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.id,
      children:item.children?item.children.map(children=>({
        label:children.name,
        value:children.id
      })):[]
    }))
  }
  else {
    message.error(res.data.message)
  }
}

//获取用户信息
const userSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/permission/system_user/page',
    method: 'get',
    params:{
      page_num:1,
      page_size:99
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.real_name,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}

//获取调拨方式
const transferMethodSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/transfer_method/selector',
    method: 'get',
    
  })
  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.value
    }))
  }
  else {
    message.error(res.data.message)
  }
}

//获取快递公司选择器
const expressCompanySelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/express_provider/selector',
    method: 'get',
  })
  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.value
    }))
  }
  else {
    message.error(res.data.message)
  } 
}


const assetsTypeSelect = async  ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_brand/selector',
    method: 'get'
  })
  try{
     if (res.data.message == 'success') {
      baseData.brand = res.data.data
      let result = res.data.data.map(item=>({
        label:item.name,
        value:item.value
      }))
      return result
  }
  else {
    message.error(res.data.message)

  }
  }
  catch(err){
    console.log(err)
    message.error("请求异常，请联系管理员")
  }
 
}

const typeSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_type/page',
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.name,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}

// 资产状态选择器
const assetsStatusSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_status/selector',
    method: 'get'
  })

  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.value
    }))
  }
  else {
    message.error(res.data.message)
  } 
}

// 采购方式选择器
const procurementMethodSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/procurement_method/selector',
    method: 'get'
  })

  if (res.data.message == 'success') {
    return res.data.data.map(item=>({
      label:item.name,
      value:item.value
    }))
  }
  else {
    message.error(res.data.message)
  }
}


//资产型号选择器
const assetModelSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/asset_model/page',
    method: 'get',
    params: {
      page_num: 1,
      page_size: 99
    }
  })

  if (res.data.message == 'success') {
    // 按品牌名称分组
       const uniqueBrands = [...new Set(res.data.data.list.map(item => item.brand))];
    
    // 创建品牌分组对象
    const brandGroups = uniqueBrands.map(brand => ({
      label: brand,
      value: brand,
      children: res.data.data.list
        .filter(item => item.brand === brand)
        .map(item => ({
          label: item.name,
          value: item.id
        }))
    }));

    return brandGroups;
  }
  else {
    message.error(res.data.message)
  }
}

//子系统选择器
const subSystemSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/sub_system/page',
    method: 'get',
    params:{
      page_num:1,
      page_size:99
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.name,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}
//资产选择器
const assetsSelect = async ()=>{
  const res = await serviceAxios({
    url: '/v1/asset/page',
    method: 'get',
    params:{
      page_num:1,
      page_size:9999
    }
  })
  if (res.data.message == 'success') {
    return res.data.data.list.map(item=>({
      label:item.alias,
      value:item.id
    }))
  }
  else {
    message.error(res.data.message)
  }
}

Promise.all([
  
  // selector.storesUnderTheBrand(),
  deptSelect(),
  userSelect(),
  transferMethodSelect(),
  expressCompanySelect(),  

  subSystemSelect(),
  assetModelSelect(),
  procurementMethodSelect(),
  assetsStatusSelect(),
  typeSelect(),
  assetsTypeSelect(),

  assetsSelect()
  ]).then(([
    // storesUnderTheBrand,
    deptSelect,
    userSelect,
    transferMethodSelect,
    expressCompanySelect,

    subSystemSelect,
    assetModelSelect,
    procurementMethodSelect,
    assetsStatusSelect,
    typeSelect,
    assetsTypeSelect,
  assetsSelect
    ])=>{
  // options.new_store_id = storesUnderTheBrand
  options.new_dept_id = deptSelect
  options.approver_id = userSelect
  options.method = transferMethodSelect
  options.express_provider = expressCompanySelect

  options.sub_system_id = subSystemSelect
  options.asset_model_id = assetModelSelect
  options.procurement_method = procurementMethodSelect
  options.status = assetsStatusSelect
  options.type_id = typeSelect
  options.brand = assetsTypeSelect

  options.asset_id = assetsSelect



  options.creator_id = options.approver_id
  options.model_id = options.asset_model_id
  console.log(options)
}).catch(err=>{
  console.log(err)
  message.error("请求异常，请联系管理员")
})








</script>
<style lang="less" scoped>
@import "@/assets/css/modalTable.css";
@import "@/assets/css/modal.css";
.container {
  width: 100%;
  height: 100%;
}
.searchDivContainer {
  padding-top: 0px;
  display: flex;
  align-items: center; /* 如果你希望垂直居中 */
  justify-content: space-between; /* 子元素之间的空间平均分布 */
}

.right-buttons {
  margin-left: auto; /* 将这个容器推到右边 */
  display: flex;
  align-items: center; /* 根据需要调整垂直对齐 */
}

.addDataButton {
  margin-left: 5px; /* 如果需要的话，添加一些水平间距 */
  margin-bottom: 2px;
}

.modal-input {
  width: 200px;
  // margin: 0 8px 8px 0;
}

.uniform-width {
  width: 100%; /* 或者其他你想要的宽度值 */
}

.scroll-container {
  height: 200px; /* 设置容器的高度 */
  overflow-y: scroll; /* 允许在y轴（垂直方向）上滚动 */
  border: 1px solid black; /* 添加边框以便更好地查看容器 */
  padding: 10px; /* 添加内边距 */
}

.green-light {
  width: 13px;
  height: 13px;
  display: inline-block;
  background-color: rgb(37, 167, 37);
  border-radius: 50%;
  // margin-bottom: 10px;
}

button {
  padding: 5px 10px;
  margin-top: 10px;
}

.editable-row-a {
  margin: 2px;
  margin-left: 5px;
  color: #1890ff;
}

.trigger-table {
  width: 100%;
  border-top: 1px solid #e6eaee;
  border-left: 1px solid #e6eaee;
  margin-bottom: 40px;
}
.trigger-table tr td {
  width: 200px;
  height: 35px;
  line-height: 35px;
  box-sizing: border-box;
  padding: 0 10px;
  border-bottom: 1px solid #e6eaee;
  border-right: 1px solid #e6eaee;
}
.trigger-table tr td.column {
  background-color: #eff3f6;
  color: #111111;
  width: 15%;
}

.interface-name {
  background-color: rgb(241, 241, 248);
}
.interface-input {
  padding-left: 0px;
  width: 100px;
}
.interface-selector {
  padding-left: 0px;
  margin-left: -15px;
  width: 150px;
}
.interface-input-number {
  padding-left: 0px;
  margin-left: -15px;
  width: 100px;
}

.mustRequired {
  color: red;
  margin-left: 0px;
}
.searchItem {
  width: 150px;
  margin-right: 10px;
}

.interfaces-box {
  display: flex;
  .interfaces-item {
    padding: 18px;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
  }
  .interfaces-item-no-selected {
    padding: 18px;
    cursor: pointer;
    font-size: 20px;
  }
}
</style>