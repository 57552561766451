<!-- eslint-disable vue/no-v-model-argument -->
<template>

  <div class="login_container">
    <div class="login_box">
      <div style="padding:20px"> <img style="width: 230px; height: 60px"
             src="@/static/login/yuhe.png"
             alt="Logo" /> </div>
      <a-form class="form_box"
              style="padding:30px;margin-top:-25px"
              :model="formState"
              name="basic"
              @finish="onFinish"
              @finishFailed="onFinishFailed">
        <a-form-item label="帐号"
                     name="username"
                     :rules="[{ required: true, message: '请输入帐号' }]">
          <a-input v-model:value="formState.username" />
        </a-form-item>

        <a-form-item label="密码"
                     name="password"
                     :rules="[{ required: true, message: '请输入密码' }]">
          <a-input-password v-model:value="formState.password" />
        </a-form-item>

        <!-- <a-form-item
          name="remember"
          :wrapper-col="{ offset: 8, span: 16 }"
        >
          <a-checkbox v-model:checked="formState.remember">Remember me</a-checkbox>
        </a-form-item> -->

        <a-form-item class="btns">
          <a-button type="primary"
                    html-type="submit">登录</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>

</template>
<script setup>
import TableComponent from '@/components/TableComponent.vue'
import { useRouter } from 'vue-router'
import serviceAxios from "../utils/serviceAxios";
import { message } from "ant-design-vue";
import { toRaw } from "@vue/reactivity";
import { reactive } from "vue";
import qs from 'qs';
import encrypt from "../utils/encrypt"
import interfaces from '@/config';

import { useStore } from 'vuex';
const store = useStore();

let access_token = ''  //token

const router = useRouter()

const formState = reactive({
  username: "",
  password: "",
});

//登录
const onFinish = () => {
  // formState.password = crypto.loginUserCrypto(formState.password)
  let data = {
    username: formState.username,
    password: encrypt.loginUserCrypto(formState.password),
    client_id: 1
  }
  console.log(data)
  data = toRaw(data)
  serviceAxios({
    url: "/v1/secure/token",
    method: "post",
    data: qs.stringify(data),
  })
    .then((result) => {
      console.log(result); 

      access_token = result.data.access_token
      localStorage.setItem('token', access_token)
      // store.state.text = true;
      // console.log(store.state.text)
      message.success('登录成功');
      router.push(
        {
          path: '/home'
        })
    })
    .catch((err) => {

      console.log(err);
      message.error(err.response.data.detail);
    });


};

let regionData = []
//区域信息
const clearRegionalTree = (data) => {
  let response = [];
  data.forEach((item) => {
    if (item.children != undefined) {
      response.push({
        label: item.name,
        value: item.id,
        children: clearRegionalTree(item.children),
      });
    } else {
      response.push({
        label: item.name,
        value: item.id,
      });
    }
  });
  return response;
};
const regionTree = () => {
  serviceAxios({
    url: interfaces.reginalTree,
    method: "get",
  })
    .then((result) => {
      // regionData = clearRegionalTree(result.data.data[0].children);
      regionData = clearRegionalTree(result.data.data);
      localStorage.setItem("region", JSON.stringify(regionData))
    })
    .catch((err) => {
      message.error("请求异常，请联系管理员");
      console.log(err);
    });
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const checkPermissios = () => {
  if (localStorage.getItem("id") == null) {
    regionTree();
  }
  else {
    router.push({ path: '/home' })
  }
}
checkPermissios()

</script>



<style lang='less' scoped>
// .btns {
//   display: flex;
//   justify-content: flex-end;

// }
.login_container {
  background-color: rgb(250, 250, 250);
  background: "/src/assets/login.jpg";
  height: 100%;
  background-position: center center;
  background-size: cover;
}

.login_box {
  width: 300px;
  height: 300px;
  background-color: rgb(250, 250, 250);
  // border-radius: 3px;
  // border-style: groove;

  // outline-style: outset;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .a-form-item {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
}
</style>